import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 23 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.833 4.333h-3.125V.167H2.125A2.09 2.09 0 0 0 .042 2.25v11.458h2.083a3.12 3.12 0 0 0 3.125 3.125 3.12 3.12 0 0 0 3.125-3.125h6.25a3.12 3.12 0 0 0 3.125 3.125 3.12 3.12 0 0 0 3.125-3.125h2.083V8.5l-3.125-4.167Zm-.52 1.563L21.354 8.5h-4.646V5.896h2.605ZM5.25 14.75a1.045 1.045 0 0 1-1.042-1.042c0-.573.47-1.041 1.042-1.041.573 0 1.042.468 1.042 1.041s-.47 1.042-1.042 1.042Zm2.313-3.125a3.093 3.093 0 0 0-2.313-1.042c-.927 0-1.74.407-2.312 1.042h-.813V2.25h12.5v9.375H7.563ZM17.75 14.75a1.045 1.045 0 0 1-1.042-1.042c0-.573.47-1.041 1.042-1.041.573 0 1.042.468 1.042 1.041s-.47 1.042-1.042 1.042Z"
    }, null, -1)
  ])))
}
export default { render: render }